const TokenKey = 'Authorization';
const UserIdKey = 'userId';

export function getToken() {
  return localStorage.getItem(TokenKey);
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  return localStorage.removeItem(TokenKey);
}

export function getUserId() {
  return localStorage.getItem(UserIdKey);
}

export function setUserId(userId) {
  return localStorage.setItem(UserIdKey, userId);
}

export function removeUserId() {
  return localStorage.removeItem(UserIdKey);
}
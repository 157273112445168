import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { getToken } from './utils/auth'

// markdown
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

import Prism from 'prismjs';
import 'prismjs/themes/prism.css';

import CopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

VMdPreview.use(CopyCodePlugin());
VMdPreview.use(vuepressTheme, {
  Prism
});


Vue.use(VMdPreview);

// bus
Vue.prototype.$bus = new Vue();

// element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


// lucky
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

// 前置守卫
router.beforeEach((to, from, next) => {
  const token = getToken();
  if (!token && to.path !== '/login') {
    next('/login'); // 如果没有 token 且目标路径不是登录页面，则重定向到登录页面
  } else {
    next();
  }
});


Vue.config.productionTip = false



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

// 重写了原型上的push方法，统一的处理了错误信息
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
}


const routes = [
  {
    path: '/',
    redirect: '/lucky'
  },
  {
    path: '/login',
    component: resolve => require(['../views/LoginView.vue'], resolve),
    meta: { title: 'login' }
  },
  {
    path: '/',
    component: resolve => require(['../components/HomeView.vue'], resolve),
    meta: { title: 'home' },
    children: [
      {
        path: '/chat',
        component: resolve => require(['../views/ChatView.vue'], resolve),
        meta: { title: 'ChatGPT' },
        children: [
          {
            path: '/chat/:chatId/:chatName', // 动态路由
            component: resolve => require(['../components/DetailView.vue'], resolve),
            meta: { title: 'Chat Detail' }
          },
        ]
      },

      {
        path: '/role',
        component: resolve => require(['../views/RoleView.vue'], resolve),
        meta: { title: 'role' },
        children: [
          {
            path: '/role/:chatId', // 动态路由
            component: resolve => require(['../components/RoleDesc.vue'], resolve),
            meta: { title: 'role desc' }
          },
        ]
      },
      {
        path: '/shop',
        component: resolve => require(['../views/ShopView.vue'], resolve),
        meta: { title: 'shop' }
      },

    ]
  },
  {
    path: '/lucky',
    component: resolve => require(['../views/LuckyView.vue'], resolve),
    meta: { title: 'raffle' }
  },
  {
    path: '/pay_return',
    component: resolve => require(['../views/PayReturn.vue'], resolve),
    meta: { title: 'pay_return' }
  },

  {
    path: '/fire',
    component: resolve => require(['../components/FireworkView.vue'], resolve),
    meta: { title: 'firework' }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
})



export default router
